import { Box, Skeleton, HStack, Tag, Text, Tooltip } from "@chakra-ui/react";
import { ReactNode, useContext } from "react";
import AuthContext from "../../../contexts/AuthContext";
import { useDefenderEnrollment } from "../../../hooks/tenants.hooks";
import { navigateToAdminConsent } from "../../../utils/utils";

const ADMIN_CONSENT_CODE = "AADSTS65001";

export default function DefenderSection({
  tenantId,
}: Readonly<{ tenantId: string }>) {
  const { isGov } = useContext(AuthContext);
  const defenderEnrollmentQuery = useDefenderEnrollment(tenantId);

  const getDefenderColorScheme = (
    success: number | undefined,
    total: number | undefined
  ) => {
    if (typeof success === "number" && typeof total === "number") {
      if (success === -1 || total === -1) return "gray";
      if ((success === 0 && total === 0) || (success < total && success > 0))
        return "yellow";
      if (success === total) return "green";
    }
    return "red";
  };

  let content: ReactNode;
  if (defenderEnrollmentQuery.isLoading) {
    content = (
      <Skeleton>
        <Tag>placeholder</Tag>
      </Skeleton>
    );
  } else if (defenderEnrollmentQuery.data?.active) {
    content = (
      <Skeleton isLoaded={!!defenderEnrollmentQuery.data}>
        <HStack>
          <Box marginRight={3}>
            <HStack>
              <Text fontSize="xs">MacOS </Text>
              <Tag
                colorScheme={getDefenderColorScheme(
                  defenderEnrollmentQuery.data.macOSSuccess,
                  defenderEnrollmentQuery.data.macOSTotal
                )}
                size="sm"
              >
                {(() => {
                  if (defenderEnrollmentQuery.data) {
                    switch (defenderEnrollmentQuery.data.macOSTotal) {
                      case -1: {
                        return "config profile not deployed";
                      }
                      case 0: {
                        return "no devices enrolled";
                      }
                      default: {
                        return `${defenderEnrollmentQuery.data.macOSSuccess} / ${defenderEnrollmentQuery.data.macOSTotal}`;
                      }
                    }
                  }
                })()}
              </Tag>
            </HStack>
          </Box>
          <Box>
            <HStack>
              <Text fontSize="xs">Windows </Text>
              <Tag
                colorScheme={getDefenderColorScheme(
                  defenderEnrollmentQuery.data?.windowsSuccess,
                  defenderEnrollmentQuery.data?.windowsTotal
                )}
                size="sm"
              >
                {(() => {
                  if (defenderEnrollmentQuery.data) {
                    switch (defenderEnrollmentQuery.data.windowsTotal) {
                      case -1: {
                        return "config profile not deployed";
                      }
                      case 0: {
                        return "no devices enrolled";
                      }
                      default: {
                        return `${defenderEnrollmentQuery.data.windowsSuccess} / ${defenderEnrollmentQuery.data.windowsTotal}`;
                      }
                    }
                  }
                })()}
              </Tag>
            </HStack>
          </Box>
        </HStack>
      </Skeleton>
    );
  } else if (
    defenderEnrollmentQuery.error?.message.includes(ADMIN_CONSENT_CODE)
  ) {
    content = (
      <Tag
        colorScheme="red"
        size="sm"
        _hover={{ cursor: "pointer" }}
        onClick={() => navigateToAdminConsent(tenantId, isGov)}
      >
        Click here to grant Admin consent
      </Tag>
    );
  } else if (defenderEnrollmentQuery.data?.active === false) {
    content = (
      <Tag colorScheme="gray" size="sm">
        Not enabled
      </Tag>
    );
  } else {
    content = (
      <Tooltip label={defenderEnrollmentQuery.error?.message}>
        <Tag colorScheme="red" size="sm">
          Error
        </Tag>
      </Tooltip>
    );
  }

  return (
    <Box>
      <Text fontSize="xs" fontWeight="bold">
        Defender Enrollment{" "}
      </Text>
      {content}
    </Box>
  );
}
