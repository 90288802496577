import { Box, Button } from "@chakra-ui/react";
import { UsbAccessRequest } from "../../../../../services/atomus-internal-apis/atomus-internal-apis.types";

interface IApprovalButtonsProps {
  onClick: () => void;
}

interface IButtonSetProps {
  request: UsbAccessRequest;
  buttonAction: (request: UsbAccessRequest, approve: boolean) => void;
}

function ApproveButton({ onClick }: Readonly<IApprovalButtonsProps>) {
  return (
    <Button
      width="100px"
      variant="outline"
      mr="10px"
      colorScheme="blue"
      onClick={onClick}
    >
      Approve
    </Button>
  );
}

function BlockButton({ onClick }: Readonly<IApprovalButtonsProps>) {
  return (
    <Button width="100px" variant="outline" colorScheme="red" onClick={onClick}>
      Block
    </Button>
  );
}

export function ButtonSet({
  request,
  buttonAction,
}: Readonly<IButtonSetProps>) {
  if (request.approvalStatus === "pending") {
    return (
      <>
        <ApproveButton onClick={() => buttonAction(request, true)} />
        <BlockButton onClick={() => buttonAction(request, false)} />
      </>
    );
  } else if (request.approvalStatus === "approved") {
    return (
      <Box ml="110px">
        <BlockButton onClick={() => buttonAction(request, false)} />
      </Box>
    );
  } else if (request.approvalStatus === "declined") {
    return <ApproveButton onClick={() => buttonAction(request, true)} />;
  } else {
    return <></>;
  }
}
