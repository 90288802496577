import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Skeleton,
  Spacer,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { BsEnvelope, BsWindow } from "react-icons/bs";
import SortableTh from "../../../../Components/SortableTh/SortableTh";
import TooltipTag from "../../../../Components/TooltipTag/TooltipTag";
import { useDeviceAppRecommendations } from "../../../../hooks/devices.hooks";
import { useDefenderIsActive } from "../../../../hooks/tenants.hooks";
import useMicrosoftToken from "../../../../hooks/tokens.hooks";
import { useDataSorting } from "../../../../hooks/utils.hooks";
import { toastError, toastSuccess } from "../../../../Providers/ToastProvider";
import { sendEmail } from "../../../../services/atomus-internal-apis/atomus-internal-apis.service";
import {
  AppRecommendationRemediationType,
  Device,
  SecurityRecommendation,
} from "../../../../services/atomus-internal-apis/atomus-internal-apis.types";

function AppRecommendationType({
  type,
}: Readonly<{
  type: AppRecommendationRemediationType | null;
}>) {
  let color: string;
  if (type === "ConfigurationChange") {
    color = "blue";
  } else if (type === "Uninstall") {
    color = "red";
  } else if (type === "Update") {
    color = "yellow";
  } else if (type === "Upgrade") {
    color = "purple";
  } else {
    color = "gray";
  }
  return <Tag colorScheme={color}>{type ?? "Unknown"}</Tag>;
}

export default function SecurityRecommendationsSection({
  device: { deviceId, id, deviceName },
  tenantId,
}: Readonly<{
  device: Required<Pick<Device, "id" | "deviceId" | "deviceName">>;
  tenantId: string;
}>) {
  const {
    isLoading: activeLoading,
    error: activeError,
    data: activeData,
  } = useDefenderIsActive(tenantId);
  const { isRefetching, data, error, refetch, isLoading } =
    useDeviceAppRecommendations(tenantId);
  const thisDeviceRecommendations = useMemo<
    | Pick<
        SecurityRecommendation,
        | "id"
        | "recommendationName"
        | "recommendationCategory"
        | "remediationType"
        | "relatedComponent"
      >[]
    | null
  >(() => data?.[deviceId] ?? null, [data, deviceId]);

  const { activeItems, handleSort, sortDir, sortKey } = useDataSorting(
    thisDeviceRecommendations ?? undefined
  );

  const [refreshWasClicked, setRefreshWasClicked] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { getInternalApiToken } = useMicrosoftToken();
  const [emailIsSending, setEmailIsSending] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (isRefetching && refreshWasClicked) {
      setIsRefreshing(true);
    } else if (!isRefetching && refreshWasClicked) {
      setIsRefreshing(false);
      setRefreshWasClicked(false);
    }
  }, [isRefetching, refreshWasClicked]);

  const handleSendEmail = async () => {
    try {
      setEmailIsSending(true);
      const token = await getInternalApiToken();
      await sendEmail(token, tenantId, "defender-app-recommendations", {
        deviceId: id,
        deviceObjectId: deviceId,
        deviceName,
      });
      toastSuccess(toast, "Successfully sent notification email");
    } catch (error) {
      console.error(error);
      toastError(toast, "Error sending notification email");
    }
    setEmailIsSending(false);
  };

  if (activeLoading || activeData === false) {
    // dont show unless defender has been setup
    return <></>;
  }

  let content: ReactNode;
  if (
    isLoading ||
    isRefreshing ||
    (thisDeviceRecommendations &&
      thisDeviceRecommendations.length > 0 &&
      thisDeviceRecommendations.length !== activeItems.length)
  ) {
    content = <Skeleton width="25%" height="1.5em" />;
  } else if (error || (activeError && !data && !isLoading)) {
    content = (
      <Box>
        <TooltipTag
          tagText="Error"
          tooltipText={
            error?.message ??
            activeError?.message ??
            "an unknown error occurred"
          }
          tagColorScheme="red"
        />
      </Box>
    );
  } else if (thisDeviceRecommendations === null) {
    content = (
      <Box>
        <TooltipTag
          tagText="Defender device not found"
          tooltipText="This likely means the device has not been enrolled in defender"
          tagColorScheme="red"
        />
      </Box>
    );
  } else if (thisDeviceRecommendations?.length === 0) {
    content = (
      <Box width="fit-content">
        <Tag colorScheme="green">No application security recommendations</Tag>
      </Box>
    );
  } else {
    content = (
      <Flex flexDir="column" gap="12px">
        <Flex gap="12px">
          <Tag colorScheme="yellow">
            <Flex gap="12px" alignItems="center">
              <BsWindow />
              {activeItems.length} application security recommendations found
            </Flex>
          </Tag>
          <Button
            size="xs"
            variant="outline"
            colorScheme="blue"
            leftIcon={<BsEnvelope />}
            onClick={handleSendEmail}
            isLoading={emailIsSending}
          >
            Notify user
          </Button>
        </Flex>
        <Accordion allowToggle>
          <AccordionItem>
            <AccordionButton>
              <Flex alignItems="center">
                <Text>Details</Text>
                <Spacer />
                <AccordionIcon />
              </Flex>
            </AccordionButton>
            <AccordionPanel>
              <Table>
                <Thead>
                  <Tr>
                    <SortableTh
                      isSorted={sortKey === "recommendationName"}
                      sortDir={sortDir}
                      sortFunc={() => handleSort("recommendationName")}
                    >
                      Description
                    </SortableTh>
                    <SortableTh
                      isSorted={sortKey === "relatedComponent"}
                      sortDir={sortDir}
                      sortFunc={() => handleSort("relatedComponent")}
                    >
                      Application
                    </SortableTh>
                    <SortableTh
                      isSorted={sortKey === "remediationType"}
                      sortDir={sortDir}
                      sortFunc={() => handleSort("remediationType")}
                    >
                      Remediation Type
                    </SortableTh>
                  </Tr>
                </Thead>
                <Tbody>
                  {activeItems.map((rec) => (
                    <Tr key={rec.id}>
                      <Td>{rec.recommendationName}</Td>
                      <Td>{rec.relatedComponent}</Td>
                      <Td>
                        <AppRecommendationType
                          type={rec.remediationType ?? null}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Flex>
    );
  }

  return (
    <Flex flexDir="column" gap="8px">
      <Flex alignItems="baseline" gap="12px">
        <Text fontSize="lg">Application Security Recommendations</Text>
        <Spacer />
        <Button
          size="xs"
          variant="ghost"
          onClick={() => {
            setRefreshWasClicked(true);
            refetch({ throwOnError: true });
          }}
        >
          Refresh
        </Button>
      </Flex>
      <Flex paddingX="16px" flexDir="column" gap="8px">
        {content}
      </Flex>
    </Flex>
  );
}
