import { AuthRequiredTenant } from "../../services/atomus-internal-apis/atomus-internal-apis.types";
import { useMsal } from "@azure/msal-react";
import { Td, Tr } from "@chakra-ui/react";
import useMicrosoftToken from "../../hooks/tokens.hooks";

interface ITenantAuthLinkProps {
  tenant: AuthRequiredTenant;
  refetchTenants: () => void;
}

export default function TenantAuthLink({
  tenant,
  refetchTenants,
}: ITenantAuthLinkProps) {
  const { instance } = useMsal();
  const { getInternalApiToken } = useMicrosoftToken();

  const handleAuth = async () => {
    const config = instance.getConfiguration();
    // since config is a reference to the instance's config (i checked the source
    // code and it does not copy the value in getConfiguration) this updates the
    // instances tenant id
    config.auth.azureCloudOptions.tenant = tenant.tenantId;
    // with the updated tenant id forcing an interactive login now will prompt the
    // user for the MFA they have setup for the tenant
    await getInternalApiToken({ forceInteractive: true });
    refetchTenants();
  };

  return (
    <Tr
      _hover={{ cursor: "pointer", background: "gray.200" }}
      onClick={handleAuth}
    >
      <Td>{tenant.companyName}</Td>
      <Td>{tenant.tenantId}</Td>
    </Tr>
  );
}
