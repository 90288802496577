import {
  Box,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spacer,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDevices } from "../../../../hooks/devices.hooks";
import { IntuneMobileDeviceTypes } from "../../../../services/atomus-internal-apis/atomus-internal-apis.constants";
import { Device } from "../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import DeviceHeader from "./DeviceHeader/DeviceHeader";
import HealthcheckSection from "./HealthcheckSection";
import StatSection from "./StatSection";
import SecurityRecommendationsSection from "./SecurityRecommendationsSection";
import { useDefenderIsActive } from "../../../../hooks/tenants.hooks";

interface IDeviceDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  tenantId: string;
  deviceId: string | null;
}

export default function DeviceDetailsModal({
  isOpen,
  onClose,
  tenantId,
  deviceId,
}: Readonly<IDeviceDetailsModalProps>) {
  const { data } = useDevices(tenantId);
  const [modalDevice, setModalDevice] = useState<Device | null>(null);
  const { data: defenderIsActive } = useDefenderIsActive(tenantId);

  useEffect(() => {
    if (deviceId && data) {
      const foundDevice = data.find((device) => device.id === deviceId);
      if (foundDevice) {
        setModalDevice(foundDevice);
      } else {
        setModalDevice(null);
      }
    }
  }, [data, deviceId]);

  return (
    <>
      {modalDevice && (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          size="6xl"
          scrollBehavior="inside"
        >
          <ModalOverlay />
          <ModalContent>
            <DeviceHeader
              device={modalDevice}
              onClose={onClose}
              tenantId={tenantId}
            />
            <ModalBody mt={6}>
              <StatSection device={modalDevice} />
              {!IntuneMobileDeviceTypes.includes(
                modalDevice.operatingSystem.toLowerCase()
              ) ? (
                <Box mb="20px">
                  <Divider mt={2} />

                  <HealthcheckSection
                    deviceName={modalDevice.displayName}
                    deviceOs={modalDevice.operatingSystem}
                    tenantId={tenantId}
                  />

                  {modalDevice.deviceId && defenderIsActive && (
                    <SecurityRecommendationsSection
                      tenantId={tenantId}
                      device={{
                        deviceId: modalDevice.deviceId,
                        deviceName: modalDevice.displayName,
                        id: modalDevice.id,
                      }}
                    />
                  )}
                </Box>
              ) : (
                <Spacer mt={3} />
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}
