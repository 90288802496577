import { Flex, Spacer, CloseButton, Text, Tag } from "@chakra-ui/react";
import { IntuneDeviceOs } from "../../../../../constants/microsoft.constants";
import { Device } from "../../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import DeviceActions from "./DeviceActions";

export default function DeviceHeader({
  device,
  tenantId,
  onClose,
}: Readonly<{ device: Device; tenantId: string; onClose: () => void }>) {
  const getLastSignedInDate = (date?: Date) => {
    if (!date) {
      // unable to parse date string
      return "n/a";
    } else {
      return date.toLocaleString();
    }
  };

  return (
    <Flex alignItems="center" padding="8px">
      <Flex alignItems="center" gap="16px">
        <Text fontSize="xl" fontWeight="semibold" ml="16px">
          {device.displayName}
        </Text>
        <Tag colorScheme={device.accountEnabled ? "green" : "yellow"} size="sm">
          {device.accountEnabled ? "Enabled" : "Disabled"}
        </Tag>
        {device.approximateLastSignInDateTime && (
          <Text>
            Last signed in:{" "}
            {getLastSignedInDate(
              new Date(device.approximateLastSignInDateTime)
            )}
          </Text>
        )}
      </Flex>
      <Spacer />
      <Flex alignItems="center" gap="12px">
        {(device.operatingSystem === IntuneDeviceOs.MAC ||
          device.operatingSystem === IntuneDeviceOs.WINDOWS) && (
          <DeviceActions
            device={device}
            onClose={onClose}
            tenantId={tenantId}
          />
        )}
        <CloseButton onClick={onClose} margin={2} />
      </Flex>
    </Flex>
  );
}
