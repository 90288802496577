import { useMsal } from "@azure/msal-react";
import {
  Box,
  Button,
  Center,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Column } from "react-table";
import LoadingBar from "../../Components/LoadingBar";
import Page from "../../Components/PageContainer";
import SortableTable from "../../Components/SortableTable";
import AuthContext from "../../contexts/AuthContext";
import { useTenants } from "../../hooks/tenants.hooks";
import { TenantData } from "../../services/atomus-internal-apis/atomus-internal-apis.types";
import TenantAuthLink from "./TenantAuthLink";

const COLUMNS: Column<TenantData>[] = [
  {
    Header: "Company name",
    accessor: "companyName",
  },
  {
    Header: "Tenant domain",
    accessor: "domain",
  },
  {
    Header: "Tenant id",
    accessor: "tenantId",
  },
];

export default function TenantsListPage() {
  const navigate = useNavigate();
  const { role } = useContext(AuthContext);
  const { instance } = useMsal();

  const { data, error, isLoading, isError, isRefetching, refetch } =
    useTenants();

  const navToTenant = (tenantData: TenantData) => {
    navigate(`/tenants/${tenantData.tenantId}`, {
      state: {
        shortForm: tenantData.shortForm,
        domain: tenantData.domain,
        companyName: tenantData.companyName,
      },
    });
  };

  if (role === "none") {
    return (
      <Page>
        <LoadingBar />
      </Page>
    );
  }

  if (role === "companyAdmin") {
    const tenantId = instance.getActiveAccount()?.tenantId;
    if (!tenantId) {
      return (
        <Page>
          <Center color="red">
            Unable to get tenant id for active account
          </Center>
        </Page>
      );
    }
    return (
      <Navigate to={`/tenants/${instance.getActiveAccount()?.tenantId}`} />
    );
  }

  return (
    <Page>
      {(isLoading || isRefetching) && (
        <Progress isIndeterminate borderRadius="md" />
      )}
      {isError && <Center>{error.message}</Center>}
      {!isLoading && !isRefetching && data && (
        <>
          <SortableTable
            tableProps={{ columns: COLUMNS, data: data.success }}
            onClick={navToTenant}
          />
          {data.authRequired.length > 0 && (
            <Box marginY={4}>
              <Text fontSize="xl" fontWeight="semibold" marginY={4}>
                Authentication required
              </Text>
              <Table marginY={4}>
                <Thead>
                  <Tr>
                    <Th>Company Name</Th>
                    <Th>Tenant ID</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.authRequired.map((tenant) => (
                    <TenantAuthLink
                      key={tenant.tenantId as string}
                      tenant={tenant}
                      refetchTenants={refetch}
                    />
                  ))}
                </Tbody>
              </Table>
            </Box>
          )}
          {data.error.length > 0 && (
            <Box marginY={4}>
              <Text fontSize="xl" fontWeight="semibold" marginY={4}>
                Tenant errors
              </Text>
              <Table marginY={4}>
                <Thead>
                  <Tr>
                    <Th>Tenant ID</Th>
                    <Th>Error message</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.error.map((tenant) => (
                    <Tr>
                      <Td>{tenant.tenantId}</Td>
                      <Td>{tenant.error}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          )}
          <Center>
            <Button
              colorScheme="blue"
              variant="ghost"
              mt={8}
              onClick={() => refetch({ throwOnError: true })}
            >
              Refresh
            </Button>
          </Center>
        </>
      )}
    </Page>
  );
}
