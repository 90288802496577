import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Progress,
  Skeleton,
  Spacer,
  Stat,
  StatLabel,
  Tag,
  Text,
} from "@chakra-ui/react";
import { ReactNode, useEffect, useState } from "react";
import AegisVersionTag from "../../../../Components/AegisVersionTag/AegisVersionTag";
import TooltipTag from "../../../../Components/TooltipTag/TooltipTag";
import {
  useAegisVersionWithTenantVersion,
  useTenantDevicesHealthchecks,
} from "../../../../hooks/devices.hooks";
import { HealthcheckRow } from "../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import HealthcheckResult from "./HealthcheckResult";

interface IHealthcheckSectionProps {
  deviceName: string;
  tenantId: string;
  deviceOs: string;
}

export default function HealthcheckSection({
  deviceName,
  tenantId,
  deviceOs,
}: Readonly<IHealthcheckSectionProps>) {
  const [refreshWasClicked, setRefreshWasClicked] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { data, isError, isLoading, refetch, isRefetching } =
    useTenantDevicesHealthchecks(tenantId);
  const [thisDeviceData, setThisDeviceData] = useState<HealthcheckRow[]>();
  const {
    data: versionData,
    error: versionError,
    isFetching: versionIsFetching,
  } = useAegisVersionWithTenantVersion(tenantId, deviceName, deviceOs);

  useEffect(() => {
    if (isRefetching && refreshWasClicked) {
      setIsRefreshing(true);
    } else if (!isRefetching && refreshWasClicked) {
      setIsRefreshing(false);
      setRefreshWasClicked(false);
    }
  }, [isRefetching, refreshWasClicked]);

  useEffect(() => {
    setThisDeviceData(
      data?.filter((healthCheckRow) =>
        healthCheckRow.namesArray.includes(deviceName.toLowerCase())
      )
    );
  }, [data, deviceName, setThisDeviceData]);

  let content: ReactNode;
  if (isError) {
    content = <Tag colorScheme="red">Error</Tag>;
  } else if (isLoading || isRefreshing) {
    content = <Progress isIndeterminate borderRadius="md" mt={4} />;
  } else if (thisDeviceData && thisDeviceData.length > 0) {
    content = (
      <Box mx={4}>
        <HStack spacing={2} marginBottom={4}>
          <Stat>
            <StatLabel>Healthcheck ID</StatLabel>
            <Text fontSize="xs">{thisDeviceData[0].id_g}</Text>
          </Stat>
          <Stat>
            <StatLabel>Aegis version</StatLabel>
            {versionError && (
              <TooltipTag
                tagText="Error loading version data"
                tooltipText={versionError.message}
                tagColorScheme="red"
              />
            )}
            {versionIsFetching && (
              <Skeleton>
                <Tag>0.0.0</Tag>
              </Skeleton>
            )}
            {versionData && <AegisVersionTag versionInfo={versionData} />}
          </Stat>
          <Stat>
            <StatLabel>Username</StatLabel>
            <Text fontSize="xs">{thisDeviceData[0].username_s}</Text>
          </Stat>
          <Stat>
            <StatLabel>Tenant ID</StatLabel>
            <Text fontSize="xs">{thisDeviceData[0].TenantId}</Text>
          </Stat>
        </HStack>
        <Accordion allowToggle>
          <AccordionItem>
            <AccordionButton paddingLeft="0">
              <Flex width="100%" alignItems="center">
                <Text>Details</Text>
                <Spacer />
                <AccordionIcon />
              </Flex>
            </AccordionButton>
            <AccordionPanel borderBottom="none">
              <Text fontSize="md" fontWeight="semibold">
                Tests
              </Text>
              <Accordion allowToggle my={4}>
                {thisDeviceData
                  .filter((result) => result.status_s !== "info")
                  .map((result) => (
                    <HealthcheckResult
                      result={result}
                      deviceName={deviceName}
                      key={result.stepName_s}
                    />
                  ))}
              </Accordion>
              <Text fontSize="md" fontWeight="semibold">
                Diagnostics
              </Text>
              <Accordion allowToggle my={4}>
                {thisDeviceData
                  .filter((result) => result.status_s === "info")
                  .map((result) => (
                    <HealthcheckResult
                      result={result}
                      deviceName={deviceName}
                      key={result.stepName_s}
                    />
                  ))}
              </Accordion>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    );
  } else if (thisDeviceData === null || thisDeviceData?.length === 0) {
    content = (
      <Tag ml="15px" colorScheme="red">
        Not found
      </Tag>
    );
  } else {
    content = <Center>Unknown state</Center>;
  }
  return (
    <Box marginY={6}>
      <Flex alignItems="baseline" mb={4}>
        <Text fontSize="lg">Latest healthcheck</Text>
        <Spacer />
        <Button
          variant="ghost"
          colorScheme="gray"
          size="xs"
          ml={4}
          onClick={() => {
            setRefreshWasClicked(true);
            refetch({ throwOnError: true });
          }}
        >
          Refresh
        </Button>
      </Flex>
      {content}
    </Box>
  );
}
